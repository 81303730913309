import { configureStore } from "@reduxjs/toolkit";
import survey  from "../Reducers/surveySlice";
import responseSlice from "../Reducers/responseSlice";
import currentQuestionReducer from "../Reducers/currentQuestionSlice";


export const store = configureStore({
    reducer:{
        surveyJSON:survey,
        responses:responseSlice,
        currentQuestion: currentQuestionReducer,
    }
})