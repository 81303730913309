import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import parse from "html-react-parser";
import OtherText from "../../CommonComponents/OtherTextInput";
import randomizeOptions, {
  randomizeGroupsOrder,
  shuffleArray,
} from "../../CommonComponents/Randomization";
import { getGridStyle } from "../../CommonComponents/layout";
import { CustomQuestion } from "../../theme/QuestionLabel";
import { CustomInstruction } from "../../theme/InstructionLabel";
import { CustomResponse } from "../../theme/ResponseLabel";

const MultiSelectAnswerButton = ({ question }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherText, setOtherText] = useState({});
  const responses = useSelector((store) => store.responses);
  const dispatch = useDispatch();
  const [displayOptions, setDisplayOptions] = useState([]);

  useEffect(() => {
    if (!question) {
      console.error("Question is undefined or null");
      return;
    }

    const groups = question.groups || [];
    const options = question.options || [];

    const anchors = options.filter((option) => option.isAnchor);
    const nonAnchors = options.filter((option) => !option.isAnchor);

    const randomizedGroups = question.groupRandomization
      ? randomizeGroupsOrder(groups)
      : groups;

    const mappedGroups = randomizedGroups.map((group) => ({
      ...group,
      options: (group.options || [])
        .map((optionIndex) =>
          options.find((option) => option.code === optionIndex.toString())
        )
        .filter(Boolean),
    }));

    const randomizedGroupsWithOptions = mappedGroups.map((group) => ({
      ...group,
      options: question.randomization
        ? randomizeOptions(group.options)
        : group.options,
    }));

    const ungroupedOptions = nonAnchors.filter(
      (option) =>
        !randomizedGroupsWithOptions
          .flatMap((group) => group.options)
          .includes(option)
    );

    const randomizedUngroupedOptions = question.randomization
      ? randomizeOptions(ungroupedOptions)
      : ungroupedOptions;

    const combinedOptions = [];

    options.forEach((option) => {
      if (option.isAnchor) return;

      const groupContainingOption = randomizedGroupsWithOptions.find((group) =>
        group.options.some((groupOption) => groupOption.code === option.code)
      );

      if (
        groupContainingOption &&
        !combinedOptions.includes(groupContainingOption)
      ) {
        combinedOptions.push(groupContainingOption);
      } else if (!groupContainingOption) {
        const ungroupedOption = randomizedUngroupedOptions.find(
          (ungrouped) => ungrouped.code === option.code
        );
        if (ungroupedOption) {
          combinedOptions.push({
            id: `ungrouped-${ungroupedOption.code}`,
            options: [ungroupedOption],
          });
        }
      }
    });

    const finalDisplayOptions = [];

    options.forEach((option) => {
      if (option.isAnchor) {
        const groupContainingAnchor = randomizedGroupsWithOptions.find(
          (group) =>
            group.options.some(
              (groupOption) => groupOption.code === option.code
            )
        );

        if (!groupContainingAnchor) {
          finalDisplayOptions.push({
            id: `anchor-${option.code}`,
            title: "",
            options: [option],
          });
        }
      } else {
        const combinedOption = combinedOptions.find((combined) =>
          combined.options
            ? combined.options.some((opt) => opt.code === option.code)
            : combined.code === option.code
        );

        if (combinedOption && !finalDisplayOptions.includes(combinedOption)) {
          finalDisplayOptions.push(combinedOption);
        }
      }
    });

    randomizedUngroupedOptions.forEach((option) => {
      if (
        !finalDisplayOptions.find((item) =>
          item.options.some((opt) => opt.code === option.code)
        )
      ) {
        finalDisplayOptions.push({
          id: `ungrouped-${option.code}`,
          options: [option],
        });
      }
    });

    if (question.randomization || question.groupRandomization) {
      const anchoredUngroupedOptions = finalDisplayOptions.filter((option) =>
        option.id.startsWith("anchor-")
      );
      const nonAnchoredUngroupedOptions = finalDisplayOptions.filter(
        (option) =>
          option.id.startsWith("ungrouped-") && !option.id.startsWith("anchor-")
      );
      const anchoredGroups = randomizedGroupsWithOptions.filter(
        (group) => group.isAnchor
      );
      const nonAnchoredGroups = randomizedGroupsWithOptions.filter(
        (group) => !group.isAnchor
      );

      const shuffledUngroupedOptions = question.randomization
        ? shuffleArray(nonAnchoredUngroupedOptions)
        : nonAnchoredUngroupedOptions;
      const shuffledGroups = question.groupRandomization
        ? shuffleArray(nonAnchoredGroups)
        : nonAnchoredGroups;

      let combinedFinalDisplayOptions = [];

      finalDisplayOptions.forEach((item, index) => {
        if (item.id.startsWith("anchor-")) {
          combinedFinalDisplayOptions.push(item);
        } else if (item.id.startsWith("ungrouped-")) {
          combinedFinalDisplayOptions.push(shuffledUngroupedOptions.shift());
        } else if (item.id.startsWith("group")) {
          const group =
            anchoredGroups.find((g) => g.id === item.id) ||
            shuffledGroups.shift();
          combinedFinalDisplayOptions.push(group);
        }
      });

      anchoredUngroupedOptions.forEach((anchor) => {
        if (!combinedFinalDisplayOptions.includes(anchor)) {
          combinedFinalDisplayOptions.push(anchor);
        }
      });

      setDisplayOptions(combinedFinalDisplayOptions);
    } else {
      setDisplayOptions(finalDisplayOptions);
    }
  }, [question]);

  useEffect(() => {
    if (responses && responses[question.questionId]) {
      const response = responses[question.questionId];
      if (Array.isArray(response)) {
        // Set selected options
        setSelectedOptions(response.map((res) => res.code));

        // Set other text for options that have the "Other_text" property
        const _otherText = {};
        response.forEach((res) => {
          if (res.Other_text) {
            _otherText[res.code] = res.Other_text;
          }
        });
        setOtherText(_otherText);
      }
    }
  }, [responses, question.questionId]);

  useEffect(() => {
    const currentOptions = question.options.filter((option) =>
      selectedOptions.includes(option.code)
    );
    const response = currentOptions.map((option) => ({
      code: option.code,
      ...(option.isOther &&
        otherText[option.code] && { Other_text: otherText[option.code] }),
    }));
    if (selectedOptions.length || Object.keys(otherText).length) {
      dispatch(setCurrentQuestion({ id: question.questionId, response }));
    }
  }, [
    selectedOptions,
    otherText,
    dispatch,
    question.questionId,
    question.options,
  ]);

  const handleOptionClick = (code) => {
    const option = question.options.find((opt) => opt.code === code);

    if (option.isExclusive) {
      setSelectedOptions([code]);
    } else {
      setSelectedOptions((prev) => {
        if (prev.includes(code)) {
          return prev.filter((opt) => opt !== code);
        }
        return [
          ...prev.filter((opt) => {
            const selectedOption = question.options.find((o) => o.code === opt);
            return !selectedOption?.isExclusive;
          }),
          code,
        ];
      });
    }
    setOtherText((prev) => ({ ...prev, [code]: "" }));
  };

  const handleOtherTextChange = (code, event) => {
    const { value } = event.target;
    setOtherText((prev) => ({ ...prev, [code]: value }));
  };

  return (
    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}

        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>
      <div className="mt-4">
        <div style={getGridStyle(question.columns)}>
        {displayOptions.map((group) => (
  <div key={group.id}>
    {group.title && <div className="card-title">{group.title}</div>}
    {group.options.map((option) => (
      <CustomResponse
        id={`${question.questionId}_${option.code}`}
        key={`${question.questionId}_${option.code}`}
        className="response-container"
      >
        <Button
          className={`button-width h-auto text-start border-none text-black ${
            selectedOptions.includes(option.code)
              ? `selected-bg text-white`
              : `base-bg`
          }`}
          id={`${question.questionId}_${option.code}`}
          key={`${question.questionId}_${option.code}`}
          onClick={() => handleOptionClick(option.code)}
        >
          {parse(option.label)}
        </Button>

        {option.isOther && selectedOptions.includes(option.code) && (
          <OtherText
            id={`${question.questionId}_${option.code}_Other`}
            name="otherText"
            value={otherText[option.code] || ""}
            onChange={(event) => handleOtherTextChange(option.code, event)}
            className="other-textarea"
            placeholder={option.label}
          />
        )}
      </CustomResponse>
    ))}
  </div>
))}

        </div>
      </div>
    </>
  );
};

export default MultiSelectAnswerButton;
