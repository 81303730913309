const config = {
  development: {
    BASE_URL: process.env.REACT_APP_PROD_BASE_URL,
    SURVEY_CODE: process.env.REACT_APP_SURVEY_CODE,
    ENVIRONMENT: 'development'
  },
  production: {
    BASE_URL: process.env.REACT_APP_PROD_BASE_URL,
    SURVEY_CODE: process.env.REACT_APP_SURVEY_CODE,
    ENVIRONMENT: 'production'
  },
  test: {
    BASE_URL: process.env.REACT_APP_TEST_BASE_URL,
    SURVEY_CODE: process.env.REACT_APP_SURVEY_CODE,
    ENVIRONMENT: 'test'
  }
};

// Get the current environment (default to development)
const currentEnv = 'development'; //process.env.NODE_ENV || 'development';

// Export the configuration for the current environment
export default config[currentEnv];
