import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RadioButton from "./singleResponse/RadioButton/RadioButton.js";
import ThumbRating from "./singleResponse/ThumbRatingQuestion/ThumbRatingQuestion.js";
import NumericSlider from "./singleResponse/NumericSlider/NumericSlider.js";
import StarRating from "./singleResponse/starRating/StarRatingMode.jsx";
import HorizontalRatingScale from "./singleResponse/Horizontal Rating Scale/HorizontalRatingScale.js";
import DropDownSingleMultiselect from "./singleResponse/DropDownSingleMultiselect/DropDownSingleMulti.js";
import AnswerButton from "./singleResponse/AnswerButton/AnswerButton.js";
import GridBars from "./singleResponse/GridBars/GridBars.js";
import { Card, CardTitle } from 'reactstrap';
import { setCurrentQuestion } from "../../src/redux/Reducers/currentQuestionSlice.js";
import { addResponse } from "../redux/Reducers/responseSlice.js";
import { CustomButton } from "./theme/Button.jsx";
import { CustomError } from "./theme/Error.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import BooleanQuestion from "./singleResponse/BooleanQuestion/BooleanQuestion.js";
import MultiSelectAnswerButton from "./multiResponse/MutliSelectAnswerButton/MultiSelectAnswerButton.js";
import MultiSelectCheckbox from "./multiResponse/MultiSelectCheckbox/MutliSelectCheckbox.js";
import TextSlider from "./singleResponse/TextSliderQuestion/TextSliderQuestion.js";
import ImageSingleSelectComponent from "./singleResponse/ImageSingleSelect/ImageSingleSelect.js";
import DragAndDrop from "./multiResponse/DragAndDrop/DragAndDrop.js";
import HorizontalTextSlider from "./singleResponse/TextSliderQuestion/HorizontalTextSlider.js";
import ThankYouPage from "./CommonComponents/ThankyouPage.jsx";
import { initalizeSurvey } from "../redux/Reducers/surveySlice.js";
import config from "./../config.js";

export default function RenderSurvey() {
  const [selected, setSelected] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");  // State for error message
  const surveyJson = useSelector((state) => state.surveyJSON || []);
  const responses = useSelector((state) => state.responses || {}); // Get responses from Redux
  const currentQuestion = useSelector((state) => state.currentQuestion);
  const [showThankYou, setShowThankYou] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyJson[selected]?.script) {
      try {
        eval(surveyJson[selected].script);
      } catch (error) {
        console.error("Error executing script:", error);
      }
    }
  }, [selected, surveyJson]);

  const returnControl = (question) => {
    if (question.type === "single") {
      switch (question.mode) {
        case "radio": return <RadioButton question={question} />;
        case "thumbRating": return <ThumbRating question={question} />;
        case "slider": return <NumericSlider question={question} />;
        case "textSlider": return <TextSlider question={question} />;
        case "horizontalTextSlider": return <HorizontalTextSlider question={question} />;
        case "StarRating": return <StarRating question={question} />;
        case "HorizontalRating": return <HorizontalRatingScale question={question} />;
        case "dropdown": return <DropDownSingleMultiselect question={question} />;
        case "button": return <AnswerButton question={question} />;
        case "grid": return <GridBars question={question} />;
        case "boolean": return <BooleanQuestion question={question} />;
        case "ImageSingleSelect": return <ImageSingleSelectComponent question={question} />
        default: return <RadioButton question={question} />;
      }
    }

    else if (question.type === "multi") {
      switch (question.mode) {
        case "button": return <MultiSelectAnswerButton question={question} />;
        case "checkbox": return <MultiSelectCheckbox question={question} />;
        case "DragAndDrop": return <DragAndDrop question={question} />;
        case "ImageSingleSelect": return <ImageSingleSelectComponent question={question} />
        case "dropdown": return <DropDownSingleMultiselect question={question} />;
        default: return <MultiSelectCheckbox question={question} />;
      }
    }

    else {
      return <RadioButton question={question} />;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        debugger;
        const url = `${config.BASE_URL}?surveycode=${config.SURVEY_CODE}`;
        const response = await fetch(url);

        // // Check if response status is OK
        // if (!response.ok) {
        //   throw new Error(`HTTP error! Status: ${response.status}`);
        // }

        // // Ensure the content type is JSON
        // const contentType = response.headers.get("content-type");
        // if (!contentType || !contentType.includes("application/json")) {
        //   throw new Error("Received non-JSON response");
        // }

        const data = await response.json();
        dispatch(initalizeSurvey(data.survey.Questionnaire.Questions));
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const handleSkipToQuestion = (event) => {
    const questionIndex = parseInt(event.target.value);
    if (!isNaN(questionIndex)) {
      const selectedQuestion = surveyJson[questionIndex];
      const savedResponse = responses[selectedQuestion.id];
      const savedOtherText = responses[`${selectedQuestion.id}_other`]; // Load Other response
      // Update current question with saved response
      dispatch(setCurrentQuestion({
        ...selectedQuestion,
        response: { code: savedResponse, Other_text: savedOtherText || "" }
      }));
      // Update the selected question index
      setSelected(questionIndex);
    }
  };

  const validateOtherOption = (option, response) => {
    if (!option.isOther || !response?.Other_text) {
      return true; // No 'Other' option to validate or no 'Other' response provided
    }

    const openEndType = option.OpenEndtype;
    const otherText = response.Other_text;

    switch (openEndType) {
      case 'text':
        // Validate character length
        if (option.textLength && otherText.length > option.textLength) {
          setErrorMessage(`Please limit your response to ${option.textLength} character${option.textLength > 1 ? 's' : ''}.`);
          return false;
        }
        // Validate word count
        if (option.wordLength) {
          const wordCount = otherText.split(/\s+/).filter(word => word.length > 0).length;
          if (wordCount > option.wordLength) {
            setErrorMessage(`Please limit your response to ${option.wordLength} word${option.wordLength > 1 ? 's' : ''}.`);
            return false;
          }
        }
        return true; // No specific validation for text beyond length checks

      case 'numeric':
        if (!/^\d+$/.test(otherText)) {
          setErrorMessage("Please enter a numeric value for the 'Other' option.");
          return false;
        }
        break;

      case 'email':
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(otherText)) {
          setErrorMessage("Please enter a valid email address for the 'Other' option.");
          return false;
        }
        break;

      default:
        return true; // If no specific type, consider it valid
    }

    return true; // Passed all validations
  };


  // Function to scroll to the error message
  const scrollToError = () => {
    var element = document.getElementById("text-start");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigation = (direction) => {
    if (direction === 'next') {
      // Validate the current question if the question's required is not false
      if (
        surveyJson[selected].required !== false &&
        (!currentQuestion.response || !currentQuestion.id)
      ) {
        setErrorMessage("Please select a response before moving to the next question.");
        scrollToError();
        return; // Prevent navigation if validation fails
      } else if (validate()) {
        // Save the response and move to the next question
        dispatch(addResponse({
          [currentQuestion.id]: currentQuestion.response.code ? currentQuestion.response.code : currentQuestion.response
        }));
        if (getSelectedQuestion()?.isOther) {
          const option = getSelectedQuestion();
          const validationPassed = validateOtherOption(option, currentQuestion.response);
          if (!validationPassed) {
            scrollToError();
            return; // Prevent navigation if validation for 'Other' fails
          }

          dispatch(addResponse({ [`${currentQuestion.id}_other`]: currentQuestion.response.Other_text }));
        }
        // Move to the next question
        const newSelected = selected + 1;
        if (newSelected < surveyJson.length) {
          setSelected(newSelected);
          dispatch(setCurrentQuestion({})); // Reset current question
          setErrorMessage(""); // Clear any previous error messages
        } else {
          handleSubmit();
        }
      } else if (!surveyJson[selected].required) {
        const responsesArray = Array.isArray(currentQuestion.response)
          ? currentQuestion.response
          : currentQuestion.response
            ? [currentQuestion.response] // Wrap in array if it's a single object
            : []; // Default to an empty array if response is undefined
        // Check for any 'Other' options that are required within the options
        const otherOptionNeedsValidation = surveyJson[selected].options.some(option => {
          // Check if the option is "Other" and is selected
          const isSelected = responsesArray.some(selectedOption => selectedOption?.code === option.code);
          // Validate if the option is required and the 'Other' text is missing
          return option.isOther && (option.required !== false || option.required === undefined) && isSelected && !currentQuestion.response?.Other_text;
        });
        if (otherOptionNeedsValidation) {
          setErrorMessage("Please fill in the other option.");
          scrollToError();
          return; // Prevent navigation if validation fails for 'Other' option
        }
        // Move to the next question without saving response
        const newSelected = selected + 1;
        if (newSelected < surveyJson.length) {
          setSelected(newSelected);
          dispatch(setCurrentQuestion({})); // Reset current question
          setErrorMessage(""); // Clear any previous error messages
        } else {
          handleSubmit();
        }
      }
    } else if (direction === 'prev') {
      // Move to the previous question
      const newSelected = selected - 1;
      if (newSelected >= 0) {
        setSelected(newSelected);
        dispatch(setCurrentQuestion({})); // Reset current question
        setErrorMessage(""); // Clear any previous error messages
      }
    }
  };

  const getSelectedQuestion = () => {
    return surveyJson[selected]?.options.find(option => option.code === currentQuestion.response.code);
  };

  const validate = () => {
    // Check if there's a current question and response
    if (currentQuestion?.response) {
      console.log('Validating response:', currentQuestion.response);
      // Handle multi-select options
      if (Array.isArray(currentQuestion.response)) {
        const { minSelections, maxSelections, exactSelections } = surveyJson[selected];
        const selectedCodes = currentQuestion.response.map(option => option.code);
        const selectedCount = selectedCodes.length;
        const hasExclusive = selectedCodes.some(code => surveyJson[selected].options.find(opt => opt.code === code && opt.isExclusive));
        let validationError = '';
        if (!hasExclusive) {
          if (exactSelections !== undefined && selectedCount !== exactSelections) {
            validationError = `Please select exactly ${exactSelections} option${exactSelections > 1 ? 's' : ''}.`;
          } else if (minSelections !== undefined && selectedCount < minSelections) {
            validationError = `Please select at least ${minSelections} option${minSelections > 1 ? 's' : ''}.`;
          } else if (maxSelections !== undefined && selectedCount > maxSelections) {
            validationError = `Please select no more than ${maxSelections} option${maxSelections > 1 ? 's' : ''}.`;
          }
        }
        for (const selectedOption of currentQuestion.response) {
          const option = surveyJson[selected]?.options.find(opt => opt.code === selectedOption.code);
          if (option?.isOther && (option.required !== false || option.required === undefined) && !selectedOption.Other_text) {
            validationError = "Please fill in the other option.";
            scrollToError();
            break;  // Exit loop if there's an error
          }
        }
        setErrorMessage(validationError);
        scrollToError();
        return validationError === '';
      } else {
        const selectedOption = getSelectedQuestion();
        if (selectedOption?.isOther) {
          const otherRequired = selectedOption.required !== false || selectedOption.required === undefined;
          if (otherRequired && !currentQuestion.response.Other_text) {
            setErrorMessage("Please fill in the other option.");
            scrollToError();
            return false;
          }
        }
        setErrorMessage("");
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    let isValid = true;
    let errorMessage = "";

    // Ensure currentQuestion and its response are defined
    if (!currentQuestion || !currentQuestion.response) {
      errorMessage = "Unable to retrieve the current question or response.";
      scrollToError();
      setErrorMessage(errorMessage);
      return;
    }
    // Only validate the last question in surveyJson
    const lastQuestionIndex = surveyJson.length - 1;
    const lastQuestion = surveyJson[lastQuestionIndex];
    const lastQuestionResponse = responses[lastQuestion.id];
    const lastQuestionOtherResponse = responses[`${lastQuestion.id}_other`];
    // Check if the last question is required and validate the response
    if (lastQuestion.required && (!lastQuestionResponse || Object.keys(lastQuestionResponse).length === 0)) {
      errorMessage = "Please answer the last question before submitting.";
      scrollToError();
      isValid = false;
    }
    // Check if "Other" text is required for the last question
    if (lastQuestion.isOther && lastQuestion.required !== false && !lastQuestionOtherResponse) {
      errorMessage = "Please fill in the 'Other' option for the last question.";
      scrollToError();
      isValid = false;
    }

    if (isValid) {
      // Save the response and finalize the survey submission
      const responseToSave = currentQuestion.response.code ? currentQuestion.response.code : currentQuestion.response;

      dispatch(addResponse({
        [currentQuestion.id]: responseToSave
      }));

      if (getSelectedQuestion()?.isOther) {
        dispatch(addResponse({ [`${currentQuestion.id}_other`]: currentQuestion.response.Other_text || "" }));
      }

      dispatch(setCurrentQuestion({})); // Reset current question
      setShowThankYou(true);
    } else {
      // Show error message
      scrollToError();
      setErrorMessage(errorMessage);
    }
  };

  if (showThankYou) {
    return (
      <ThankYouPage />
      // <Card className="row justify-content-center align-items-center">
      //   <div className="col-md-6">
      //     <CardTitle tag="h1">Thank You for Completing the Survey!</CardTitle>
      //     <p className="lead">Your responses have been submitted successfully.</p>
      //     <button className="btn btn-primary" onClick={() => window.location.reload()}>
      //       Take another survey
      //     </button>
      //     <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '300px', maxWidth: '100%', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
      //       {/* <pre>{JSON.stringify(responses, null, 2)}</pre> */}
      //     </div>
      //   </div>
      // </Card>
    );
  }

  return (
    <>
      <div className="basePrimary">
        <div className="mx-5 text-2xl text-white">ERA Survey Tool</div>
      </div>
      {/* Skip To Dropdown */}
      <div className="skip-to-container">
        {/* {JSON.stringify(currentQuestion, null, 2)} */}
        <label htmlFor="skipTo">Skip to Question:</label>
        <select id="skipTo" onChange={handleSkipToQuestion} value={selected}>
          {surveyJson.map((question, index) => (
            <option key={index} value={index}>
              Question {index + 1}: {question.questionId || "Untitled"}
            </option>
          ))}
        </select>
      </div>
      <div className="baseSecondary"></div>
      <div className="base">
        <div className="text-start" id="text-start">
          {errorMessage && <CustomError id="survey-error" text={errorMessage} />}
          {surveyJson.map(
            (question, index) =>
              selected === index && (
                <div key={index}>
                  {returnControl(question)}
                </div>
              )
          )}
        </div>
      </div>
      <div className="base-button button-container">
        <div>
          <CustomButton
            text="<<"
            disabled={selected === 0}
            onClick={() => handleNavigation('prev')}
          >
            {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
            <FontAwesomeIcon icon={faBackward} />&nbsp; Prev
          </CustomButton>
          {selected === surveyJson.length - 1 ? (
            <CustomButton
              text="Submit"
              onClick={handleSubmit}
            >Submit
            </CustomButton>
          ) : (
            <CustomButton
              text=">>"
              disabled={selected === surveyJson.length - 1}
              onClick={() => handleNavigation('next')}
            >Next&nbsp; <FontAwesomeIcon icon={faForward} />
              {/* <FontAwesomeIcon icon={faArrowRight} /> */}
            </CustomButton>
          )}
        </div>
      </div>
    </>
  );
}
