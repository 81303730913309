import { createSlice } from "@reduxjs/toolkit";

const initialState = [

  {
    order: "1",
    questionId: "q1",
    type: "single",
    mode: "radio",
    required: false,
    questionText: "What is your<b> favorite</b> color?",
    questionInstruction: "Please <i>select</i> one option.",
    script: "$('#q1_instruction').css('color', 'red');",
    randomization: true,
    groupRandomization: true,
    // minSelections: 2,
    // maxSelections: 3,
    exactSelections: 2,
    //groups: reusableLists.groups,
    groups: [
      {
        id: "group1",
        title: "Primary Colors",
        isAnchor: false,
        options: [2, 3]
      },
      {
        id: "group2",
        title: "Secondary Colors",
        isAnchor: true,
        options: [4, 5, 6]
      },
      {
        id: "group3",
        title: "Colors",
        isAnchor: false,
        options: [7, 8, 9]
      }
    ],
    options: [
      { code: "1", label: "<b>A1</b>", isAnchor: true, isExclusive: true, color: "green" },
      { code: "2", label: "A2"},
      { code: "3", label: "A3", isAnchor: false, color: "red"},
      { code: "4", label: "A4", isOther: true, OpenEndtype: "email", wordLength: 50, isAnchor: true, required: false},
      { code: "5", label: "<b>A5</b>"},
      { code: "6", label: "A6"},
      { code: "7", label: "A7", isAnchor: false, color: "blue"},
      { code: "8", label: "A8"},
      { code: "9", label: "<b>A9</b>"},
      { code: "18", label: "A18"},
      { code: "28", label: "A28"},
      //...reusableLists.colorOptions,
      { code: "38", label: "A38", isAnchor: false },
      { code: "98", label: "A98", isOther: true, OpenEndtype: "text", textLength: 10, wordLength: 2, isAnchor: true, required: true},
    ],
    columns: { columns: 2 }
  },
  {
    order: "2",
    questionId: "q2",
    type: "single",
    mode: "thumbRating",
    required: true,
    questionText: "How likely are you <b>to recommend our service to a friend?",
    questionInstruction: "Please select one option.",
    options: [
      { code: "1", label: "Not likely", color: "red" },
      { code: "2", label: "Likely", color: "green" }
    ],

  },
  {
    order: "3",
    questionId: "q3",
    type: "single",
    mode: "slider",
    required: false,
    questionText: "On a scale of 1 to 100, how satisfied are you with our service?",
    questionInstruction: "Please select one option.",
    max: 100,
    min: 1,
    step: 1,
    options: [
      { code: "1", label: "1" },
      { code: "10", label: "10" },
      { code: "20", label: "20" },
      { code: "30", label: "30" },
      { code: "40", label: "40" },
      { code: "50", label: "50" },
      { code: "60", label: "60" },
      { code: "70", label: "70" },
      { code: "80", label: "80" },
      { code: "90", label: "90" },
      { code: "100", label: "100" }
    ],
    sx: {
      "& .MuiSlider-thumb": {
        "backgroundColor": "#007bff"
      },
      "& .MuiSlider-track": {
        "backgroundColor": "#007bff"
      },
      "& .MuiSlider-rail": {
        "backgroundColor": "#ddd"
      },
      "& .MuiSlider-markLabel": {
        "fontSize": "0.9em",
        "color": "#666"
      }
    },
    orientation: "horizontal"
  },
  {
    order: "4",
    questionId: "q4",
    type: "single",
    mode: "horizontalTextSlider",
    questionText: "How would you describe our service?",
    required: true,    
    randomization: false,
    min: 0,
    max: 5,
    step: 1,
    options: [
      { code: 1, label: "Poor", color: "#fc4503" },
      { code: 2, label: "Fair", color: "#fc4503" },
      { code: 3, label: "Good", color: "#fc4503" },
      { code: 4, label: "Very Good", color: "#fc4503" },
      { code: 5, label: "Excellent", isOther: true, isAnchor: true, color: '#fc9003' },
      { code: 99, label: "Other", isOther: true, color: '#03fc6f' },
    ],
  },
  {
    order: "4",
    questionId: "q4",
    type: "single",
    mode: "textSlider",
    questionText: "How would you describe our service?",
    required: true,
    orientation: "vertical",
    randomization: false,
    min: 0,
    max: 5,
    step: 1,
    options: [
      { code: 1, label: "Poor", color: "#fc4503" },
      { code: 2, label: "Fair", color: "#fc4503" },
      { code: 3, label: "Good", color: "#fc4503" },
      { code: 4, label: "Very Good", color: "#fc4503" },
      { code: 5, label: "Excellent", isOther: true, isAnchor: true, color: '#fc9003' },
      { code: 99, label: "Other", isOther: true, color: '#03fc6f' },
    ],
  },
  {
    order: "5",
    questionId: "q5",
    type: "single",
    mode: "StarRating",
    required: true,
    questionText: "On a scale of 1 to 5, how satisfied are you with our service?",
    questionInstruction: "Please rate as much as you want.",
    min: 1,
    max: 5,
    step: 1,
    options: [
      { code: 1, label: "1", color: '#fc4503' },
      { code: 2, label: "2", color: '#fc4503' },
      { code: 3, label: "3", color: '#fc4503'  },
      { code: 4, label: "4", color: '#fc9003'  },
      { code: 5, label: "5", color: '#03fc6f'  }
    ],
  },
  {
    order: "6",
    questionId: "q6",
    type: "single",
    mode: "HorizontalRating",
    randomization: false,
    required: true,
    questionText: "How old are you?",
    questionInstruction: "Please rate as much as you want.",
    options: [
      { code: "1", label: "Under 16" },
      { code: "2", label: "16-24" },
      { code: "3", label: "25-34" },
      { code: "4", label: "35-44" },
      { code: "5", label: "45-54" },
      { code: "6", label: "55-64" },
      { code: "7", label: "65-74" },
      { code: "8", label: "75 or over" },
      { code: "9", label: "Prefer not to answer" }
    ],
    orientation: "horizontal"
  },
  {
    order: "7",
    questionId: "q7",
    type: "single",
    mode: "HorizontalRating",
    randomization: false,
    required: true,
    questionText: "How satisfied are you with our service?",
    questionInstruction: "Please rate as much as you want.",
    options: [
      { code: "1", label: "1", color: '#fc4503'  },
      { code: "2", label: "2", color: '#873e23'   },
      { code: "3", label: "3", color: '#76b5c5'   },
      { code: "4", label: "4", color: '#fc9003'  },
      { code: "5", label: "5", color: '#03fc6f' }
    ],
    orientation: "horizontal"
  },
  {
    order: "8",
    questionId: "q8",
    questionText: "Do you agree with the terms and conditions?",
    questionInstruction: "Please rate as much as you want.",
    type: "single",
    mode: "boolean",
    // required: true,
    options: [
      { code: "1", label: "Yes", color: "green" },
      { code: "2", label: "No", color: "red" }
    ],
    sx: {}
  },
  {
    order: "9",
    questionId: "q9",
    questionText: "What are the location options?",
    questionInstruction: "Please select",
    type: "single",
    mode: "dropdown",
    required: true,
    isSearchable: true,
    options: [
      { code: "1", label: "Johor" },
      { code: "2", label: "Kedah" },
      { code: "3", label: "Kelantan" },
      { code: "4", label: "Melaka" },
      { code: "5", label: "Negeri Sembilan" },
      { code: "6", label: "Pahang" },
      { code: "7", label: "Perak" },
      { code: "8", label: "Perlis" },
      { code: "9", label: "Pulau Pinang" },
      { code: "10", label: "Sabah" },
      { code: "11", label: "Sarawak" },
      { code: "12", label: "Selangor" },
      { code: "13", label: "Terengganu" },
      { code: "14", label: "Wilayah Persekutuan Kuala Lumpur" },
      { code: "15", label: "Wilayah Persekutuan Labuan" },
      { code: "16", label: "Wilayah Persekutuan Putrajaya" },
      { code: "99", label: "Others", isOther: true }
    ]
  },
  {
    order: "10",
    questionId: "q10",
    questionText: "What are the job options?",
    questionInstruction: "Please rate.",
    type: "multi",
    mode: "dropdown",
    // required: true,
    isMulti: true,
    isSearchable: false,
    options: [
      { code: "1", label: "Engineer" },
      { code: "2", label: "Doctor" },
      { code: "3", label: "Teacher" },
      { code: "4", label: "Lawyer" },
      { code: "5", label: "Accountant" },
      { code: "6", label: "Architect" },
      { code: "7", label: "Nurse" },
      { code: "99", label: "Others", isOther: true }
    ]
  },
  {
    order: "11",
    questionId: "q11",
    type: "single",
    mode: "button",
    required: true,
    questionText: "What is your favorite food?",
    questionInstruction: "Please select one option.",
    randomization: true,
    // groupRandomization: true,
    // groups: [
    //   {
    //     id: "group1",
    //     title: "Primary Food",
    //     isAnchor: false,
    //     options: [1, 3]
    //   },
    //   {
    //     id: "group2",
    //     title: "Secondary Food",
    //     isAnchor: true,
    //     options: [4, 5]
    //   }
    // ],
    options: [
      { code: "1", label: "Pizza", isAnchor: false },
      { code: "2", label: "B<b>urg</b>er" },
      { code: "3", label: "Sushi" },
      { code: "4", label: "Chicken wings" },
      { code: "5", label: "Momos" },
      { code: "98", label: "Other (specify)", isOther: true, OpenEndtype: "email",  isAnchor: true }
    ],
    columns: { columns: 1 }
  },
  {
    order: "12",
    questionId: "q12",
    type: "single",
    mode: "grid",
    required: true,
    questionText: "What is your favorite color?",
    questionInstruction: "Please select one option.",
    options: [
      { code: "1", label: "Red" },
      { code: "2", label: "Blue" },
      { code: "3", label: "Green" },
      { code: "4", label: "Yellow" },
      { code: "98", label: "Other", isOther: true }
    ]
  },
  {
    order: "13",
    questionId: "q13",
    type: "multi",
    mode: "button",
    required: true,
    questionText: "What is<br> your<b> favorite</b> color?",
    questionInstruction: "Please <i>select</i> one option.",
    script: "$('#q1_instruction').css('color', 'red');",
    randomization: true,
    groupRandomization: true,
    // minSelections: 2,
    // maxSelections: 3,
    exactSelections: 2,
    //groups: reusableLists.groups,
    groups: [
      {
        id: "group1",
        title: "Primary Colors",
        isAnchor: false,
        options: [2, 3]
      },
      {
        id: "group2",
        title: "Secondary Colors",
        isAnchor: true,
        options: [4, 5, 6]
      },
      {
        id: "group3",
        title: "Colors",
        isAnchor: false,
        options: [7, 8, 9]
      }
    ],
    options: [
      { code: "1", label: "<b>A1</b>", isAnchor: true, isExclusive: true },
      { code: "2", label: "A2"},
      { code: "3", label: "A3", isAnchor: false},
      { code: "4", label: "A4", isOther: true, isAnchor: true, required: false},
      { code: "5", label: "<b>A5</b>"},
      { code: "6", label: "A6"},
      { code: "7", label: "A7", isAnchor: false,},
      { code: "8", label: "A8"},
      { code: "9", label: "<b>A9</b>"},
      { code: "18", label: "A18"},
      { code: "28", label: "A28"},
      //...reusableLists.colorOptions,
      { code: "38", label: "A38", isAnchor: false },
      { code: "98", label: "A98", isOther: true, isAnchor: true},
    ],
    columns: { columns: 2 }
  },
  {
    order: "14",
    questionId: "q14",
    type: "multi",
    mode: "checkbox",
    required: true,
    questionText: "What is<br> your<b> favorite</b> color?",
    questionInstruction: "Please <i>select</i> one option.",
    script: "$('#q1_instruction').css('color', 'red');",
    randomization: true,
    groupRandomization: true,
    minSelections: 3,
    // maxSelections: 3,
    // /exactSelections: 2,
    //groups: reusableLists.groups,
    groups: [
      {
        id: "group1",
        title: "Primary Colors",
        isAnchor: false,
        options: [2, 3]
      },
      {
        id: "group2",
        title: "Secondary Colors",
        isAnchor: true,
        options: [4, 5, 6]
      },
      {
        id: "group3",
        title: "Colors",
        isAnchor: false,
        options: [7, 8, 9]
      }
    ],
    options: [
      { code: "1", label: "<b>A1</b>", isAnchor: true, isExclusive: true },
      { code: "2", label: "A2"},
      { code: "3", label: "A3", isAnchor: false},
      { code: "4", label: "A4", isOther: true, isAnchor: true, required: false},
      { code: "5", label: "<b>A5</b>"},
      { code: "6", label: "A6"},
      { code: "7", label: "A7", isAnchor: false,},
      { code: "8", label: "A8"},
      { code: "9", label: "<b>A9</b>"},
      { code: "18", label: "A18"},
      { code: "28", label: "A28"},
      //...reusableLists.colorOptions,
      { code: "38", label: "A38", isAnchor: false },
      { code: "98", label: "A98", isOther: true, isAnchor: true},
    ],
    columns: { columns: 2 }
  },
  {
   order: "15",
   questionId: "q15",
   type: "multi",
   mode: "DragAndDrop",
   required: true,
   questionText: "What is your favorite color?",
   questionInstruction: "You can select mutiple options",
   options: [
     { code: "1", label: "Red" },
     { code: "2", label: "Blue"},
     { code: "3", label: "Green", isOther: true  },
     { code: "4", label: "Yellow"},
     { code: "5", label: "Other", isOther: true},
     { code: "6", label: "Voilet", isExclusive:true}
   ]
 },
 {
   order: "16",
   questionId: "q16",
   type: "single",
   mode: "ImageSingleSelect",
   required: true,
   questionText: "What is your favorite color?",
   questionInstruction: "Please select one option.",
   options: [
     { code: "1", label: "Red",
      image: "vitor-fontes.jpg",
      showImage: true,
      width:300,
      height:300,
      textPosition: "down" },
     { code: "2", label: "Blue",
       image: "bao-menglong.jpg",
       showImage: true,
       width:300,
       height:300,
       textPosition: "down" },
     { code: "3", label: "Green",
       image: "kate-stone-matheson.jpg",
       showImage: true,
       width:300,
       height:300,
       textPosition: "down" },
     { code: "4", label: "Yellow",
      image: "wooof-woof.jpg",
      showImage: true,
      width:300,
      height:300,
      textPosition: "down" },
     { code: "98", label: "Other",
      image: "paul-hanaoka.jpg",
      showImage: true,
      width:300,
      height:300,
      textPosition: "down", isOther: true }
   ]
 },
  { order:"17",
    questionId: "q17",
    type: "multi",
    mode:"ImageSingleSelect",
    // required: true,
    questionText: "What is your favorite color?",
    options: [
        {
            code:"1",
            label: "Red",
            image: "favicon 1.png",
            showImage: true,
            width:300,
            height:300,
            textPosition: "down"
        },
        {
            code:"2",
            label: "Blue",
            image: "image1.png",
            showImage: true,
            width:300,
            height:300,
            textPosition: "down"
        },
        {   code:"3",
            label: "Green",
            image: "image2.png",
            showImage: true,
            width:300,
            height:300,
            textPosition: "down"
        },
        {   code:"4",
            label: "Yellow",
            image: "image3.png",
            showImage: true,
            width:300,
            height:300,
            textPosition: "down"
        }
    ],
    columns : { columns: 1 }
}
];

// export const responses = createSlice({
// name :"responses",
// initialState,
// reducers :{
// addResponse (state, action){
//     return {...state,...action.payload}
// }

// }
// })

export const survey = createSlice({
  name: "survey",
  initialState,
  reducers: {
    initalizeSurvey(state, action) {
      return action.payload;
    },
  },
});

export const { initalizeSurvey } = survey.actions;

export default survey.reducer;
