import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import parse from "html-react-parser";
import OtherText from "../../CommonComponents/OtherTextInput";
import randomizeOptions, {
  randomizeGroupsOrder,
  shuffleArray,
} from "../../CommonComponents/Randomization";
import { getGridStyle } from "../../CommonComponents/layout";
import { CustomQuestion } from "../../theme/QuestionLabel";
import { CustomInstruction } from "../../theme/InstructionLabel";
import { CustomResponse } from "../../theme/ResponseLabel";

const AnswerButton = ({ question }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [otherText, setOtherText] = useState("");
  const responses = useSelector((store) => store.responses);
  const dispatch = useDispatch();
  const [displayOptions, setDisplayOptions] = useState([]);

  useEffect(() => {
    if (!question) {
      console.error("Question is undefined or null");
      return;
    }

    const groups = question.groups || [];
    const options = question.options || [];

    // console.log("options", options);
    // console.log("groups", groups);

    // Separate anchors from options
    const anchors = options.filter((option) => option.isAnchor);
    const nonAnchors = options.filter((option) => !option.isAnchor);

    // Randomize groups if groupRandomization is true
    const randomizedGroups = question.groupRandomization
      ? randomizeGroupsOrder(groups)
      : groups;

    // Map options to groups
    const mappedGroups = randomizedGroups.map((group) => ({
      ...group,
      options: (group.options || [])
        .map((optionIndex) =>
          options.find((option) => option.code === optionIndex.toString())
        )
        .filter(Boolean),
    }));

    // Randomize options within each group if randomization is true, else keep original order
    const randomizedGroupsWithOptions = mappedGroups.map((group) => ({
      ...group,
      options: question.randomization
        ? randomizeOptions(group.options)
        : group.options,
    }));

    // console.log("randomizedGroupsWithOptions", randomizedGroupsWithOptions);
    // Get ungrouped non-anchor options
    const ungroupedOptions = nonAnchors.filter(
      (option) =>
        !randomizedGroupsWithOptions
          .flatMap((group) => group.options)
          .includes(option)
    );

    // Randomize ungrouped non-anchor options if randomization is true, else keep original order
    const randomizedUngroupedOptions = question.randomization
      ? randomizeOptions(ungroupedOptions)
      : ungroupedOptions;

    // Combine groups and ungrouped options, preserving the order in options
    const combinedOptions = [];

    options.forEach((option) => {
      if (option.isAnchor) {
        // Anchors will be handled separately
        return;
      }

      const groupContainingOption = randomizedGroupsWithOptions.find((group) =>
        group.options.some((groupOption) => groupOption.code === option.code)
      );

      if (
        groupContainingOption &&
        !combinedOptions.includes(groupContainingOption)
      ) {
        combinedOptions.push(groupContainingOption);
      } else if (!groupContainingOption) {
        const ungroupedOption = randomizedUngroupedOptions.find(
          (ungrouped) => ungrouped.code === option.code
        );
        if (ungroupedOption) {
          combinedOptions.push({
            id: `ungrouped-${ungroupedOption.code}`,
            options: [ungroupedOption],
          });
        }
      }
    });

    // Reinsert anchor options at their specified positions
    const finalDisplayOptions = [];

    options.forEach((option) => {
      if (option.isAnchor) {
        // Check if the anchor option is part of a group
        const groupContainingAnchor = randomizedGroupsWithOptions.find(
          (group) =>
            group.options.some(
              (groupOption) => groupOption.code === option.code
            )
        );

        if (!groupContainingAnchor) {
          // If not part of a group, add it as an anchor
          finalDisplayOptions.push({
            id: `anchor-${option.code}`,
            title: "",
            options: [option],
          });
        }
      } else {
        const combinedOption = combinedOptions.find((combined) =>
          combined.options
            ? combined.options.some((opt) => opt.code === option.code)
            : combined.code === option.code
        );

        if (combinedOption && !finalDisplayOptions.includes(combinedOption)) {
          finalDisplayOptions.push(combinedOption);
        }
      }
    });

    // Ensure all ungrouped non-anchor options are included
    randomizedUngroupedOptions.forEach((option) => {
      if (
        !finalDisplayOptions.find((item) =>
          item.options.some((opt) => opt.code === option.code)
        )
      ) {
        finalDisplayOptions.push({
          id: `ungrouped-${option.code}`,
          options: [option],
        });
      }
    });
    // console.log("finalDisplayOptionsaaa", finalDisplayOptions);

    if (question.randomization || question.groupRandomization) {
      // Separate anchored and non-anchored items
      const anchoredUngroupedOptions = finalDisplayOptions.filter((option) =>
        option.id.startsWith("anchor-")
      );
      const nonAnchoredUngroupedOptions = finalDisplayOptions.filter(
        (option) =>
          option.id.startsWith("ungrouped-") && !option.id.startsWith("anchor-")
      );
      const anchoredGroups = randomizedGroupsWithOptions.filter(
        (group) => group.isAnchor
      );
      const nonAnchoredGroups = randomizedGroupsWithOptions.filter(
        (group) => !group.isAnchor
      );

      // Shuffle non-anchored ungrouped options and groups if applicable
      const shuffledUngroupedOptions = question.randomization
        ? shuffleArray(nonAnchoredUngroupedOptions)
        : nonAnchoredUngroupedOptions;
      const shuffledGroups = question.groupRandomization
        ? shuffleArray(nonAnchoredGroups)
        : nonAnchoredGroups;

      // Initialize combined final display options
      let combinedFinalDisplayOptions = [];

      // Add anchored items in their original positions
      finalDisplayOptions.forEach((item, index) => {
        if (item.id.startsWith("anchor-")) {
          combinedFinalDisplayOptions.push(item);
        } else if (item.id.startsWith("ungrouped-")) {
          // Add shuffled ungrouped options in place of the original non-anchored ungrouped ones
          combinedFinalDisplayOptions.push(shuffledUngroupedOptions.shift());
        } else if (item.id.startsWith("group")) {
          // Place groups in the combined array, maintaining original anchored positions
          const group =
            anchoredGroups.find((g) => g.id === item.id) ||
            shuffledGroups.shift();
          combinedFinalDisplayOptions.push(group);
        }
      });

      // Add any remaining anchored items that haven't been added yet (edge cases)
      anchoredUngroupedOptions.forEach((anchor) => {
        if (!combinedFinalDisplayOptions.includes(anchor)) {
          combinedFinalDisplayOptions.push(anchor);
        }
      });

      setDisplayOptions(combinedFinalDisplayOptions);
      // console.log("finalDisplayOptions with randomization:", combinedFinalDisplayOptions);
    } else {
      setDisplayOptions(finalDisplayOptions);
      // console.log("finalDisplayOptions without randomization:", finalDisplayOptions);
    }
  }, [question]);

  useEffect(() => {
    if (responses && responses[question.questionId]) {
      const response = responses[question.questionId];
      if (typeof response === "string") {
        setSelectedOption(response);
        setOtherText("");
      }
      // else if (typeof response === 'object') {
      //     setSelectedOption(response.code);
      //     setOtherText(response.Other_text || '');

      // }
      let _otherText = responses[`${question.questionId}_other`]
        ? responses[`${question.questionId}_other`]
        : "";
      setOtherText(_otherText);
    }
  }, [responses, question.questionId]);

  useEffect(() => {
    const currentOption = question.options.find(
      (option) => option.code === selectedOption
    );
    const response = {
      code: selectedOption,
      ...(currentOption?.isOther && otherText && { Other_text: otherText }),
    };
    if (selectedOption || otherText) {
      dispatch(setCurrentQuestion({ id: question.questionId, response }));
    }
  }, [
    selectedOption,
    otherText,
    dispatch,
    question.questionId,
    question.options,
  ]);

  const handleOptionClick = (code) => {
    setSelectedOption((prev) => (prev === code ? null : code));
    setOtherText("");
  };

  const handleOtherTextChange = (event) => {
    const { value } = event.target;
    setOtherText(value);
  };

  const { columns } = question;

  return (
    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}

        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>
      <div className="mt-4">
        <div style={getGridStyle(question.columns)}>
          {displayOptions.map((group) => (
            <div key={group.id}>
              {group.title && <div className="card-title">{group.title}</div>}
              {group.options.map((option) => (
                <div className="option-wrapper">
  <CustomResponse
    id={`${question.questionId}_${option.code}`}
    key={`${question.questionId}_${option.code}`}
  >
<div className="response-container">
  <Button
    className={`button-width h-auto text-start border-none text-black ${
      option.color
        ? selectedOption === option.code
          ? "text-white"
          : "base-bg"
        : selectedOption === option.code
        ? "selected-bg text-white"
        : "base-bg"
    }`}
    id={`${question.questionId}_${option.code}`}
    key={`${question.questionId}_${option.code}`}
    onClick={() => handleOptionClick(option.code)}
    style={{
      backgroundColor:
        selectedOption === option.code ? option.color : undefined,
    }}
  >
    {parse(option.label)}
  </Button>
  {option.isOther && selectedOption === option.code && (
    <OtherText
      id={`${question.questionId}_${option.code}_Other`}
      name="otherText"
      value={otherText}
      onChange={handleOtherTextChange}
      className="other-textarea"
      placeholder={option.label}
    />
  )}
</div>

    </CustomResponse>
  
</div>

              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AnswerButton;
