import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestion } from '../../../redux/Reducers/currentQuestionSlice';
import parse from 'html-react-parser';
import randomizeOptions from "../../CommonComponents/Randomization";
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';
 
const GridBars = ({ question }) => {
  const { questionId, questionText, questionInstruction, options } = question;
  const responses = useSelector(state => state.responses); // Retrieve responses from Redux
  const dispatch = useDispatch();
  const [selectedAnswerCode, setSelectedAnswerCode] = useState(''); // State to track selected answer
  const [randomizedOptions, setRandomizedOptions] = useState([]);
 
  // Initialize response from Redux store when component mounts
  useEffect(() => {
    if (responses && responses[questionId]) {
      setSelectedAnswerCode(responses[questionId]); // Set the initial state based on the stored response
    }
  }, [responses, questionId]);
 
  // Randomize options if required
  useEffect(() => {
    if (question.randomization) {
      setRandomizedOptions(randomizeOptions(options));
    } else {
      setRandomizedOptions(options);
    }
  }, [options, question.randomization]);
 
  // Dispatch selected answer to Redux store whenever it changes
  useEffect(() => {
    if (selectedAnswerCode) {
      dispatch(setCurrentQuestion({ id: questionId, response: selectedAnswerCode }));
    }
  }, [selectedAnswerCode, dispatch, questionId]);
 
  const handleOptionSelect = (optionCode) => {
    if (selectedAnswerCode === optionCode) {
      // Deselect if the same option is clicked again
      setSelectedAnswerCode('');
      dispatch(setCurrentQuestion({ id: questionId, response: null }));
    } else {
      // Select a new option
      setSelectedAnswerCode(optionCode);
    }
  };
 
  // Calculate the color based on the position relative to the selected answer
  const getColor = (index) => {
    const selectedOptionIndex = randomizedOptions.findIndex(option => option.code === selectedAnswerCode);
 
    if (selectedOptionIndex === -1) return '#c5bebe';
 
    // Get the CSS variable for the primary color
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
 
    // Convert the hex color to rgba with the adjusted opacity
    const [r, g, b] = primaryColor.match(/\w\w/g).map(x => parseInt(x, 16));
    const opacity = 1 - Math.abs(index - selectedOptionIndex) * 0.2;
    const baseColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
 
    // Highlight only options to the left and including the selected one
    return index <= selectedOptionIndex ? baseColor : '#c5bebe';
  };
 
  return (
    <>
      <div className="flex flex-col">
        {questionText && (
          <CustomQuestion
            id={`${questionId}_text`}
            key={`${questionId}_text`}
            text={parse(questionText)}
          />
        )}
 
        {questionInstruction && (
          <CustomInstruction
            id={`${questionId}_instruction`}
            key={`${questionId}_instruction`}
            text={parse(questionInstruction)}
          />
        )}
      </div>
      <div className="grid-options-container flex mt-3">
        {randomizedOptions.map((option, index) => (
          <div key={option.code} className="grid-option-container">
            
            <div
              id={`${questionId}_${option.code}_card`}
              key={`${questionId}_${option.code}_card`}
              className={`grid-option ${selectedAnswerCode === option.code ? 'selected' : ''}`}
              onClick={() => handleOptionSelect(option.code)}
              style={{ backgroundColor: getColor(index) }}
            >
            </div>

            <CustomResponse className="flex-col" id={`${questionId}_${option.code}_text`} key={`${questionId}_${option.code}_text`}>    
                    <div className="slider-label">
                      {parse(option.label)}
                    </div>
            </CustomResponse>
           




          </div>
        ))}
      </div>
    </>
  );
};
 
export default GridBars;