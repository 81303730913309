import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestion } from '../../../redux/Reducers/currentQuestionSlice';
import { Input } from 'reactstrap';
import parse from 'html-react-parser';
import { getGridStyle } from "../../CommonComponents/layout";
import OtherTextInput from '../../CommonComponents/OtherTextInput';
import randomizeOptions, { randomizeGroupsOrder, shuffleArray } from "../../CommonComponents/Randomization";
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';

const SingleResponseQuestion = ({ question }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [otherText, setOtherText] = useState('');
  const [randomizedOptions, setRandomizedOptions] = useState([]);
  const [displayOptions, setDisplayOptions] = useState([]);
  const dispatch = useDispatch();
  const responses = useSelector(store => store.responses);

  useEffect(() => {
    if (!question) {
      console.error("Question is undefined or null");
      return;
    }

    const groups = question.groups || [];
    const options = question.options || [];

    const anchors = options.filter((option) => option.isAnchor);
    const nonAnchors = options.filter((option) => !option.isAnchor);

    const randomizedGroups = question.groupRandomization
      ? randomizeGroupsOrder(groups)
      : groups;

    const mappedGroups = randomizedGroups.map((group) => ({
      ...group,
      options: (group.options || [])
        .map((optionIndex) =>
          options.find((option) => option.code === optionIndex.toString())
        )
        .filter(Boolean),
    }));

    const randomizedGroupsWithOptions = mappedGroups.map((group) => ({
      ...group,
      options: question.randomization
        ? randomizeOptions(group.options)
        : group.options,
    }));

    const ungroupedOptions = nonAnchors.filter(
      (option) =>
        !randomizedGroupsWithOptions
          .flatMap((group) => group.options)
          .includes(option)
    );

    const randomizedUngroupedOptions = question.randomization
      ? randomizeOptions(ungroupedOptions)
      : ungroupedOptions;

    const combinedOptions = [];

    options.forEach((option) => {
      if (option.isAnchor) {
        return;
      }

      const groupContainingOption = randomizedGroupsWithOptions.find((group) =>
        group.options.some((groupOption) => groupOption.code === option.code)
      );

      if (
        groupContainingOption &&
        !combinedOptions.includes(groupContainingOption)
      ) {
        combinedOptions.push(groupContainingOption);
      } else if (!groupContainingOption) {
        const ungroupedOption = randomizedUngroupedOptions.find(
          (ungrouped) => ungrouped.code === option.code
        );
        if (ungroupedOption) {
          combinedOptions.push({
            id: `ungrouped-${ungroupedOption.code}`,
            options: [ungroupedOption],
          });
        }
      }
    });

    const finalDisplayOptions = [];

    options.forEach((option) => {
      if (option.isAnchor) {
        const groupContainingAnchor = randomizedGroupsWithOptions.find(
          (group) =>
            group.options.some(
              (groupOption) => groupOption.code === option.code
            )
        );

        if (!groupContainingAnchor) {
          finalDisplayOptions.push({
            id: `anchor-${option.code}`,
            title: "",
            options: [option],
          });
        }
      } else {
        const combinedOption = combinedOptions.find((combined) =>
          combined.options
            ? combined.options.some((opt) => opt.code === option.code)
            : combined.code === option.code
        );

        if (combinedOption && !finalDisplayOptions.includes(combinedOption)) {
          finalDisplayOptions.push(combinedOption);
        }
      }
    });

    randomizedUngroupedOptions.forEach((option) => {
      if (
        !finalDisplayOptions.find((item) =>
          item.options.some((opt) => opt.code === option.code)
        )
      ) {
        finalDisplayOptions.push({
          id: `ungrouped-${option.code}`,
          options: [option],
        });
      }
    });

    if (question.randomization || question.groupRandomization) {
      const anchoredUngroupedOptions = finalDisplayOptions.filter((option) =>
        option.id.startsWith("anchor-")
      );
      const nonAnchoredUngroupedOptions = finalDisplayOptions.filter(
        (option) =>
          option.id.startsWith("ungrouped-") && !option.id.startsWith("anchor-")
      );
      const anchoredGroups = randomizedGroupsWithOptions.filter(
        (group) => group.isAnchor
      );
      const nonAnchoredGroups = randomizedGroupsWithOptions.filter(
        (group) => !group.isAnchor
      );

      const shuffledUngroupedOptions = question.randomization
        ? shuffleArray(nonAnchoredUngroupedOptions)
        : nonAnchoredUngroupedOptions;
      const shuffledGroups = question.groupRandomization
        ? shuffleArray(nonAnchoredGroups)
        : nonAnchoredGroups;

      let combinedFinalDisplayOptions = [];

      finalDisplayOptions.forEach((item) => {
        if (item.id.startsWith("anchor-")) {
          combinedFinalDisplayOptions.push(item);
        } else if (item.id.startsWith("ungrouped-")) {
          combinedFinalDisplayOptions.push(shuffledUngroupedOptions.shift());
        } else if (item.id.startsWith("group")) {
          const group =
            anchoredGroups.find((g) => g.id === item.id) ||
            shuffledGroups.shift();
          combinedFinalDisplayOptions.push(group);
        }
      });

      anchoredUngroupedOptions.forEach((anchor) => {
        if (!combinedFinalDisplayOptions.includes(anchor)) {
          combinedFinalDisplayOptions.push(anchor);
        }
      });

      setDisplayOptions(combinedFinalDisplayOptions);
    } else {
      setDisplayOptions(finalDisplayOptions);
    }
  }, [question]);


  useEffect(() => {
    if (responses && responses[question.questionId]) {
      const response = responses[question.questionId];
      if (typeof response === 'string') {
        setSelectedOption(response);
        setOtherText('');
      }   
      let _otherText = responses[`${question.questionId}_other`] ? responses[`${question.questionId}_other`] : '';
      setOtherText(_otherText);
    }
  }, [responses, question.questionId]);

  useEffect(() => {
    const currentOption = question.options.find(option => option.code === selectedOption);

    if (selectedOption) {
      let response = { code: selectedOption };

      if (currentOption?.isOther && otherText) {
        response = { ...response, Other_text: otherText };
      }

      dispatch(setCurrentQuestion({ id: question.questionId, response }));
    }
  }, [selectedOption, otherText, dispatch, question.questionId, question.options]);


  useEffect(() => {
    if (question.randomization) {
      setRandomizedOptions(randomizeOptions(question.options));
    } else {
      setRandomizedOptions(question.options);
    }
  }, [question.options, question.randomization]);

  const handleChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    setOtherText(''); 
  };

  const handleOtherTextChange = (event) => {
    const { value } = event.target;
    setOtherText(value);
  };

  const isOtherSelected = (option) => option.code === selectedOption && option.isOther;

  return (
    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}

        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>

      <div className="mt-4">
      <div style={getGridStyle(question.columns)}>
        {displayOptions.map((group) => (
          <div key={group.id}>
            {group.title && <div className="card-title">{group.title}</div>}
            {group.options.map((option) => (
              <CustomResponse id={`${question.questionId}_${option.code}`} key={`${question.questionId}_${option.code}`}>
               <div className="response-container">
               <div className='single-labels'>
                <input
                  className='mr-3'
                  type="radio"
                  name="option"
                  value={option.code}
                  checked={selectedOption === option.code}
                  onChange={handleChange}
                  style={{ accentColor: selectedOption === option.code ? option.color : undefined }}
                />
                {parse(option.label)}
                </div>

                {isOtherSelected(option) && (
                  <OtherTextInput
                    id={`${question.questionId}_${option.code}_Other`}
                    key={`${question.questionId}_${option.code}_Other`}
                    name="otherText"
                    value={otherText}
                    onChange={handleOtherTextChange}
                    placeholder={parse(option.label)}
                  />
                )}
                </div>
              </CustomResponse>
            ))}
          </div>
        ))}
      </div>
      </div>

    </>
  );
};

export default SingleResponseQuestion;
