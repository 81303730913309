import React from 'react';
import { CustomButton } from '../theme/Button';
import TYPImage from './TYP.png';

function ThankYouPage() {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center min-h-screen bg-gray-100 p-4">

      <div className="flex max-w-4xl w-full bg-white rounded-lg shadow-lg p-8">


        <div className="flex-1 mb-8 self-center pl-8">
          <h1 className="text-3xl font-bold text-blue-600 mb-4">Thank You!</h1>
          <p className="text-gray-700 text-lg mb-6">
          Thank you for completing the survey! We are grateful for your responses, and we are pleased to inform you that your submission has been successfully received.
          </p>
          <CustomButton
            onClick={() => window.location.reload()}
          >
            Go Back to Home
          </CustomButton>
        </div>

        <div className="flex-1 text-center">
          <img src={TYPImage} alt="Thank You" className="w-full max-w-md mx-auto" />
        </div>

      </div>
    </div>
  );
}

export default ThankYouPage;
